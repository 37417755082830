import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

import { $OptionSuffixButton, $SuffixButton } from '../Autocomplete.styles';

const Option: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const { data, innerProps, suffixOptionButton, internalId } = props;

  if (suffixOptionButton) {
    return (
      <components.Option {...props}>
        {React.Children?.map(props.children, (child, key) => {
          return (
            <$OptionSuffixButton key={key}>
              {child}

              {props.isFocused && (
                <$SuffixButton>
                  {React.createElement(suffixOptionButton, {
                    ...props,
                    id: internalId,
                  })}
                </$SuffixButton>
              )}
            </$OptionSuffixButton>
          );
        })}
      </components.Option>
    );
  }

  if (data?.isHidden) {
    return null;
  }

  return (
    <components.Option
      {...props}
      innerProps={{
        ...innerProps,
        'data-option': true,
        'data-option-label': data?.label,
        'data-option-value': data?.value,
      }}
    />
  );
};

Option.displayName = 'Option';

export default Option;
