import { darken } from 'polished';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  PLACEHOLDER_TO_DISABLE_NATIVE_AUTOCOMPLETE,
  POSITIONS,
} from '@savgroup-front-common/constants/src/shared';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import { useIsNewUiEnabled } from '../../../hooks/useIsNewUiEnabled';
import useScrollIntoView from '../../../hooks/useScroll';
import { $Container, FieldMessage, Wrapper } from '../common';
import FieldLoader from '../common/FieldLoader';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { Label } from '../common/Label/Label';

import { getBorderColor } from './Input.helpers';
import {
  $CustomIcon,
  $IconContainer,
  $IconContainerRight,
  $InputContainer,
  $InputSuffix,
  $StyledInput,
  $SuffixBottomButton,
} from './Input.styles';
import { InputProps, InputPropsRef } from './Input.types';

const handleFocusSelectAll = (e: React.FocusEvent<HTMLInputElement>) => {
  return e.target.select();
};

const Input: FC<InputPropsRef> = ({
  type = 'text',
  icon,
  isDisabled = false,
  name = undefined,
  label = null,
  postLabel = undefined,
  subLabel,
  fullWidthLabel = false,
  isRequired = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  errors = {},
  warnings = {},
  successes = {},
  minLength = undefined,
  maxLength = undefined,
  onFocus = undefined,
  selectAllOnFocus = false,
  disableBrowserAutocomplete = false,
  isLoading = false,
  placeholder = '',
  dataTestId,
  suffix = '',
  prefix = '',
  onBlur = undefined,
  onMouseEnter = undefined,
  onKeyPress = undefined,
  onKeyDown = undefined,
  onMouseLeave = undefined,
  isCentered = false,
  isRounded = true,
  isFocusAndHoverBehavioursHandled = true,
  onChange = undefined,
  readOnly = false,
  value = undefined,
  forwardedRef,
  defaultValue,
  suffixInsideInput,
  suffixButton,
  shouldFitContent,
  isScrollIntoView = false,
  iconPosition = POSITIONS.LEFT,
  tabIndex,
  shouldFocusOnMount,
  pattern,
  autofocus,
  isHorizontal = false,
  isSmall = false,
  hasIconOnError = true,
  internalId,
  suffixOptionButton,
  ...props
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();

  const { scrollIntoView } = useScrollIntoView({
    ref: inputRef,
    isScrollIntoView,
  });
  const combinedRefs = useCombinedRefs(forwardedRef, inputRef);
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useLayoutEffect(() => {
    if (
      shouldFocusOnMount &&
      combinedRefs.current &&
      combinedRefs.current.focus
    ) {
      const id = setTimeout(() => {
        if (combinedRefs.current) {
          combinedRefs.current.focus();
        }
      });

      return () => clearTimeout(id);
    }

    return undefined;
  }, [shouldFocusOnMount, combinedRefs]);

  return (
    <$Container $isHorizontal={isHorizontal}>
      {label && (
        <Label
          htmlFor={name}
          isRequired={isRequired}
          status={status}
          postLabel={postLabel}
          subLabel={subLabel}
          fullWidth={fullWidthLabel}
          isHorizontal={isHorizontal}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <Wrapper
        name={name}
        status={status}
        prefix={prefix}
        suffix={suffix}
        isFocused={isFocused}
        isHovered={isHovered}
        isRounded={isRounded}
        suffixButton={suffixButton}
        isSmall={isSmall}
        hasIconOnError={hasIconOnError}
      >
        <$InputContainer>
          {icon &&
            iconPosition === POSITIONS.LEFT &&
            typeof icon !== 'string' && (
              <$IconContainer>
                <$CustomIcon
                  icon={icon}
                  color={darken(
                    0.1,
                    getBorderColor({
                      theme,
                      status,
                      disabled: isDisabled,
                      readOnly,
                    }),
                  )}
                  size="20px"
                />
              </$IconContainer>
            )}
          <$StyledInput
            {...props}
            pattern={pattern}
            $shouldFitContent={shouldFitContent}
            id={name}
            ref={combinedRefs}
            $isCentered={isCentered}
            $isRounded={isRounded}
            $isFocusAndHoverBehavioursHandled={isFocusAndHoverBehavioursHandled}
            readOnly={readOnly}
            value={value}
            data-testid={dataTestId}
            placeholder={safeFormattedIntlString(placeholder)}
            type={type}
            $hasIcon={Boolean(icon)}
            $hasIconRight={Boolean(icon && iconPosition === POSITIONS.RIGHT)}
            postLabel={postLabel}
            name={name}
            disabled={isDisabled}
            minLength={minLength}
            maxLength={maxLength}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            autoFocus={autofocus}
            onFocus={(e) => {
              if (selectAllOnFocus) {
                handleFocusSelectAll(e);
              }
              if (onFocus) {
                onFocus(e);
              }
              if (isFocusAndHoverBehavioursHandled && !readOnly) {
                setIsFocused(true);
              }
              if (isScrollIntoView) {
                scrollIntoView();
              }
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              if (isFocusAndHoverBehavioursHandled && !readOnly) {
                setIsFocused(false);
              }
            }}
            onMouseEnter={(e) => {
              if (onMouseEnter) {
                onMouseEnter(e);
              }
              if (isFocusAndHoverBehavioursHandled && !readOnly) {
                setIsHovered(true);
              }
            }}
            onMouseLeave={(e) => {
              if (onMouseLeave) {
                onMouseLeave(e);
              }
              if (isFocusAndHoverBehavioursHandled && !readOnly) {
                setIsHovered(false);
              }
            }}
            status={status}
            isLoading={isLoading}
            autoComplete={
              disableBrowserAutocomplete
                ? PLACEHOLDER_TO_DISABLE_NATIVE_AUTOCOMPLETE
                : undefined
            }
            defaultValue={defaultValue}
            $suffixButton={suffixButton}
            tabIndex={tabIndex}
            $isSmall={isSmall}
            $isNewUiEnabled={isNewUiEnabled}
          />
          {isLoading && <FieldLoader name={name} />}
          {icon &&
            iconPosition === POSITIONS.RIGHT &&
            typeof icon !== 'string' && (
              <$IconContainerRight>
                <$CustomIcon
                  icon={icon}
                  color={darken(
                    0.1,
                    getBorderColor({
                      theme,
                      status,
                      disabled: isDisabled,
                      readOnly,
                    }),
                  )}
                  size="20px"
                />
              </$IconContainerRight>
            )}
          {suffixInsideInput && (
            <$InputSuffix
              status={status}
              isDisabled={isDisabled}
              readOnly={readOnly}
            >
              {suffixInsideInput}
            </$InputSuffix>
          )}
        </$InputContainer>
      </Wrapper>
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
      {suffixOptionButton && (
        <$SuffixBottomButton>
          {React.createElement(suffixOptionButton, {
            value: combinedRefs.current?.value,
            id: internalId,
          })}
        </$SuffixBottomButton>
      )}
    </$Container>
  );
};

export default React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input forwardedRef={ref} {...props} />
));
