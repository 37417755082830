import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}salesleads/selfcareDomain`;

interface CNameQueryResponse {
  available: boolean;
  domainName: string;
}

const getDomainNameFromCNameQuery = async ({
  cName,
}: {
  cName: string;
}): Promise<BackResponse<CNameQueryResponse> | BackResponseFailure> => {
  try {
    const url = buildUrl(ENDPOINT, {
      cName,
    });

    const response = await apiCall<BackResponse<CNameQueryResponse>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getDomainNameFromCNameQuery;
