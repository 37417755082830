import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import createGenericContext from '../../helpers/createGenericContext';

export interface NewUiContextArgs {
  isNewUiEnabled: boolean;
  onToggleNewBoEnabled: () => void;
  isDarkModeEnabled: boolean;
  onToggleDarkMode: () => void;
}

const [useNewUiContext, NewUiContextProvider] =
  createGenericContext<NewUiContextArgs>();

interface NewUiProviderProps {
  initialIsNewUiEnabled?: boolean;
  isDarkModeEnabled?: boolean;
  forcedIsNewUiEnabled?: boolean;
}

const NewUiProvider: FC<PropsWithChildren<NewUiProviderProps>> = ({
  children,
  initialIsNewUiEnabled = false,
  forcedIsNewUiEnabled = undefined,
}) => {
  const [isNewUiEnabledFromStorage, setIsNewUiEnabledFromStorage] =
    useLocalStorage<boolean>(
      LOCAL_STORAGE_KEYS.NEW_BO_ENABLED,
      initialIsNewUiEnabled,
      {
        raw: false,
        serializer: (value: boolean) => {
          if (value) {
            return 'true';
          }

          return 'false';
        },
        deserializer: (value: string) => {
          return value === 'true';
        },
      },
    );

  const [isDarkModeEnabled, setIsDarkModeEnabled] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.DARK_MODE,
    false,
    {
      raw: false,
      serializer: (value: boolean) => {
        if (value) {
          return 'true';
        }

        return 'false';
      },
      deserializer: (value: string) => {
        return value === 'true';
      },
    },
  );

  const [isDarkMode, setIsDarkMode] = useState(isDarkModeEnabled ?? false);

  const onToggleNewBoEnabled = useCallback(() => {
    setIsNewUiEnabledFromStorage(!isNewUiEnabledFromStorage);

    window.location.reload();
  }, [isNewUiEnabledFromStorage, setIsNewUiEnabledFromStorage]);

  const onToggleDarkMode = useCallback(() => {
    setIsDarkMode(!isDarkMode);
    setIsDarkModeEnabled(!isDarkMode);
  }, [isDarkMode, setIsDarkModeEnabled]);

  return (
    <NewUiContextProvider
      value={{
        isNewUiEnabled:
          forcedIsNewUiEnabled === undefined
            ? isNewUiEnabledFromStorage ?? false
            : forcedIsNewUiEnabled,
        onToggleNewBoEnabled,
        isDarkModeEnabled: isDarkMode,
        onToggleDarkMode,
      }}
    >
      {children}
    </NewUiContextProvider>
  );
};

export { NewUiProvider, useNewUiContext };
