import styled from 'styled-components';

export const $Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.newUI.dividerColors.default};
  margin: 2rem 0 2rem;
`;

export const $VerticalDivider = styled.div`
  width: 1px;
  height: 80%;
  background: ${({ theme }) => theme.newUI.dividerColors.default};
`;
