import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { FullScreenLoader } from '@savgroup-front-common/core/src/molecules/FullScreenLoader';

import { useInitializeDatadog } from '../../../../datadog.config';
import { useClientInformationContext } from '../../atoms';
import { MyAccountLoginPageNewDesign } from '../../pages/MyAccountLoginPage/MyAccountLoginPageNewDesign';

import Callback from './Callback';

export const MyAccountRoutes: React.FC = () => {
  const location = useLocation();
  const { clientInformation, sellers, isLoading } =
    useClientInformationContext();

  useInitializeDatadog();

  if (!clientInformation || !sellers.length || isLoading) {
    return <FullScreenLoader isOpen={isLoading} />;
  }

  return (
    <Switch location={location}>
      <Route exact path="/callback" render={() => <Callback />} />

      <Route path="/">
        <MyAccountLoginPageNewDesign />
      </Route>

      <Route path="/new_design">
        <MyAccountLoginPageNewDesign />
      </Route>
    </Switch>
  );
};

MyAccountRoutes.displayName = 'MyAccountRoutes';
