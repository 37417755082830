import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { LANGUAGES } from '@savgroup-front-common/constants';
import { AutocompleteOptionForHookForm } from '@savgroup-front-common/types';

import { changeLocaleLanguage } from '../../../domains/i18n/actions';
import { selectLocale } from '../../../domains/i18n/selectors';
import { safeFormattedIntlString } from '../../../formatters';
import languageChooserSchema from '../LanguageChooser.schema';
import { LanguageChooserValues } from '../LanguageChooser.types';
import messages from '../messages';

const useLangageChooser = () => {
  const currentLanguage = useSelector(selectLocale);
  const dispatch = useDispatch();

  const formContext = useForm<LanguageChooserValues>({
    resolver: yupResolver(languageChooserSchema),
    defaultValues: {
      language: {
        value: currentLanguage.toLocaleUpperCase(),
        label: safeFormattedIntlString(get(messages, currentLanguage)),
      },
    },
  });

  const { setValue } = formContext;

  const handleChangeLanguage = useCallback(
    (locale?: AutocompleteOptionForHookForm | null) => {
      if (!locale) {
        return undefined;
      }

      dispatch(
        changeLocaleLanguage({
          locale: locale.value.toLowerCase() as LANGUAGES,
        }),
      );

      return setValue('language', locale);
    },
    [dispatch, setValue],
  );

  return {
    formContext,
    currentLanguage,
    handleChangeLanguage,
  };
};

export default useLangageChooser;
