import React from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, LANGUAGES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { Divider } from '@savgroup-front-common/core/src/protons/Divider/Divider';
import {
  ReversioIcon,
  SendMailIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { $Header } from '../OnboardingSetUp/OnboardingSetUp.styles';

import messages from './messages';
import useResendSalesLeadEmailConfirmationCommand from './OnboardingConfirmation.hooks';
import {
  $AccountActivationTitle,
  $ConfirmationBody,
  $ConfirmationContainer,
  $EmailActivationMessage,
  $IconWithTitle,
  $ResendButton,
  $SpamMessage,
  $UserEmailValue,
  $VerificationMessage,
} from './OnboardingConfirmation.styles';

const OnboardingConfirmation = ({ userEmail }: { userEmail: string }) => {
  const theme = useTheme();
  const { handleResendEmail, isLoading } =
    useResendSalesLeadEmailConfirmationCommand({ userEmail });

  return (
    <>
      <$Header>
        <a href="https://www.revers.io">
          <ReversioIcon color={theme.newUI.defaultColors.menu} />
        </a>
        <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
      </$Header>
      <$ConfirmationBody>
        <$ConfirmationContainer>
          <$IconWithTitle>
            <SendMailIcon
              size="100px"
              color={theme.newUI.defaultColors.primary}
            />
            <$AccountActivationTitle>
              <SafeFormattedMessageWithoutSpread
                message={messages.accountActivationTitle}
              />
            </$AccountActivationTitle>
          </$IconWithTitle>
          <$VerificationMessage>
            <SafeFormattedMessageWithoutSpread
              message={messages.verification}
            />
          </$VerificationMessage>
          <$EmailActivationMessage>
            <SafeFormattedMessageWithoutSpread
              message={messages.emailActivation}
              values={{
                email: <$UserEmailValue>{userEmail}</$UserEmailValue>,
              }}
            />
          </$EmailActivationMessage>
          <Divider />
          <$SpamMessage>
            <SafeFormattedMessageWithoutSpread message={messages.spam} />
            <$ResendButton
              primary
              isNewUi
              type={BUTTON_TYPES.BUTTON}
              onClick={() => handleResendEmail()}
              isLoading={isLoading}
            >
              <SafeFormattedMessageWithoutSpread message={messages.resend} />
            </$ResendButton>
          </$SpamMessage>
        </$ConfirmationContainer>
      </$ConfirmationBody>
    </>
  );
};

OnboardingConfirmation.displayName = 'OnboardingConfirmation';

export default OnboardingConfirmation;
