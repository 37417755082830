import React from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FLEX_ALIGN } from '@savgroup-front-common/types';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import { getStepHeaderTitle } from './StepTitle.helpers';

interface StepTitleProps {
  stepName: STEP_CONFIG;
  sellerName?: string;
}

const StepTitle: React.FC<StepTitleProps> = ({ stepName, sellerName }) => {
  return (
    <Row start="xs">
      <Heading level={2} align={FLEX_ALIGN.LEFT}>
        <SafeFormattedMessageWithoutSpread
          message={getStepHeaderTitle(stepName, sellerName)}
        />
      </Heading>
    </Row>
  );
};

export default StepTitle;
