import get from 'lodash/get';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  availableNamedLanguages,
  LANGUAGES,
} from '@savgroup-front-common/constants';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { Autocomplete } from '../../atoms/Form';
import { safeFormattedIntlString } from '../../formatters';
import { useChangeHTMLLanguage } from '../../hooks';

import useLangageChooser from './hooks/useLangageChooser';
import { $LanguageChooserContainer } from './LanguageChooser.styles';
import messages from './messages';

interface LanguageChooserProps {
  sellerId?: string;
  onLangueChange?: (locale: LANGUAGES) => void;
  acceptedLanguages?: LANGUAGES[];
  acceptedLanguagesBySeller?: string[];
}

const LanguageChooser = ({
  onLangueChange,
  acceptedLanguages,
  acceptedLanguagesBySeller,
}: LanguageChooserProps) => {
  const intl = useIntl();
  const { formContext, currentLanguage, handleChangeLanguage } =
    useLangageChooser();

  useEffect(() => {
    if (onLangueChange) {
      onLangueChange(intl.locale as LANGUAGES);
    }
  }, [onLangueChange, intl.locale]);

  const { control } = formContext;

  const optionsLanguages = availableNamedLanguages
    .filter((option) =>
      acceptedLanguages
        ? acceptedLanguages.includes(option)
        : acceptedLanguagesBySeller?.includes(option.toLocaleUpperCase()),
    )
    .map((option) => ({
      value: option.toLocaleUpperCase(),
      label: safeFormattedIntlString(get(messages, option)),
    }));

  useChangeHTMLLanguage(currentLanguage);

  if (!acceptedLanguagesBySeller?.length && !acceptedLanguages?.length) {
    return <></>;
  }

  return (
    <$LanguageChooserContainer>
      <FormProvider {...formContext}>
        <Controller
          control={control}
          name="language"
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              componentThemeName={SUPPORTED_COMPONENTS.LANGUAGE_CHOOSER}
              options={optionsLanguages}
              onChange={handleChangeLanguage}
              value={field.value}
            />
          )}
        />
      </FormProvider>
    </$LanguageChooserContainer>
  );
};

export default React.memo(LanguageChooser);
