import React from 'react';

import { ToastContainer } from '@savgroup-front-common/core/src/atoms/Toast';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';
import { useUpdateFavicon } from 'authenticator/hooks';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

import Header from './Header/Header';
import { $PageWrapper, $StepWrapper } from './NewDesignLayout.styles';

const NewDesignLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { clientInformation, isLoading } = useGetClientInformation();
  const sellerId = clientInformation?.sellerId ?? clientInformation?.tenantId;

  useUpdateFavicon({ sellerId });

  if (isLoading || !sellerId) {
    return <BaseLoader />;
  }

  return (
    <AppErrorBoundary>
      <$PageWrapper>
        {sellerId && <Header sellerId={sellerId} />}
        <$StepWrapper>{children}</$StepWrapper>
      </$PageWrapper>
      <ExternalPortal>
        <ToastContainer topOffset="6rem" />
      </ExternalPortal>
    </AppErrorBoundary>
  );
};

export default NewDesignLayout;
