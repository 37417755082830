import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import OnboardingSchema from '../Onboarding.schema';

import useGetDomainNameFromCName from './useGetDomainNameFromCName';
import useUpsertSalesLeadCommand from './useUpsertSalesLeadCommand';

export interface OnboardingFormFieldValues {
  email: string;
  lastName: string;
  firstName: string;
  companyName: string;
  cName: string;
  phone: string;
  cgv: boolean;
}

const useOnboardingFormContext = ({
  handleUserEmail,
}: {
  handleUserEmail: (userEmail: string) => void;
}) => {
  const formContext = useForm<OnboardingFormFieldValues>({
    resolver: yupResolver(OnboardingSchema()),
  });

  const { handleSubmitSalesLead, isLoading } = useUpsertSalesLeadCommand();

  const { isDomainNameLoading, domain } = useGetDomainNameFromCName({
    formContext,
  });

  const handleFormSubmit = useCallback(
    (data: OnboardingFormFieldValues) => {
      handleSubmitSalesLead(data);
      handleUserEmail(data.email);
    },
    [handleSubmitSalesLead, handleUserEmail],
  );

  return {
    formContext,
    handleFormSubmit,
    isLoading,
    isDomainNameLoading,
    domain,
  };
};

export default useOnboardingFormContext;
