import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button, ButtonLink } from '../../atoms/button';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { HomeIcon, RefreshIcon } from '../../protons/icons';

import BaseErrorBoundary from './BaseErrorBoundary';
import messages from './messages';

interface ErrorBoundyTemplateProps {
  home?: string;
  isFullScreen?: boolean;
}

const ErrorBoundyTemplate: FunctionComponent<ErrorBoundyTemplateProps> = ({
  isFullScreen = false,
  home,
}) => {
  return (
    <BaseErrorBoundary isFullScreen={isFullScreen}>
      <Row>
        <Col>
          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={() => window.location.reload()}
            icon={<RefreshIcon />}
          >
            <SafeFormattedMessageWithoutSpread message={messages.refresh} />
          </Button>
        </Col>
        <Col>
          <ButtonLink
            secondary
            isExternal
            href={home || '/'}
            icon={<HomeIcon />}
          >
            <SafeFormattedMessageWithoutSpread message={messages.link} />
          </ButtonLink>
        </Col>
      </Row>
    </BaseErrorBoundary>
  );
};

export default ErrorBoundyTemplate;
