import { useQuery } from 'react-query';

import { CommonSellerService } from '../../../api';
import { useBanners } from '../../NotificationsProvider';

export const GetSellerAcceptedLanguages = 'getSellerAcceptedLanguages';

export const useGetSellerAcceptedLanguages = ({
  sellerId,
}: {
  sellerId: string;
}) => {
  const { pushErrors } = useBanners();

  const { data } = useQuery(
    [
      GetSellerAcceptedLanguages,
      {
        sellerId,
      },
    ],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      const response = await CommonSellerService.getSellerAcceptedLanguages({
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    acceptedLanguagesBySeller: data || [],
  };
};
