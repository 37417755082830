import React, {
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { logDebug } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { Wizard } from '../../atoms/wizard';

import useStepsOrchestrator from './hooks/useStepsOrchestrator';
import StepOrchestratorProvider from './StepsOrchestrator.context';
import {
  OnPreviousClick,
  OnSubmit,
  Step,
  StepsOrchestratorConfig,
  StepsOrchestratorHistory,
} from './StepsOrchestrator.types';
import StepsOrchestratorAnimation from './StepsOrchestratorAnimation';

interface StepsOrchestratorProps<Values, Context> {
  initialContext?: Partial<Context>;
  initialHistory?: StepsOrchestratorHistory<Context>;
  withWizard?: boolean;
  onPreviousClick?: OnPreviousClick<Values, Context>;
  /**
   * @deprecated
   */
  onClose?: () => void;
  initialStep: string;
  config: StepsOrchestratorConfig<Context>;
  onSubmit: OnSubmit<Values, Context>;
  initialValues?: Partial<Values>;
  valuesFromParent?: Record<string, unknown>;
  parentPadding?: number;
  CustomWizard?: JSXElementConstructor<{
    orderedStep: Step<Context>[];
    activeStepName: string;
  }>;
}

const StepsOrchestrator = <Values, Context = Record<string, unknown>>({
  initialContext = {} as Partial<Context>,
  initialHistory = [],
  initialStep,
  initialValues = {} as Partial<Values>,
  withWizard = true,
  onPreviousClick,
  /**
   * @deprecated
   */
  onClose = () => undefined,
  config,
  onSubmit,
  valuesFromParent = {},
  parentPadding = 2 * 14,
  CustomWizard,
}: PropsWithChildren<
  StepsOrchestratorProps<Values, Context>
>): ReactElement => {
  const {
    handlePreviousStep,
    handleNextStep,
    handleJumpToStep,
    handleAnimationEntered,
    state,
    handleSubmit,
  } = useStepsOrchestrator<Values, Context>({
    initialStep,
    config,
    onSubmit,
    onPreviousClick,
    initialContext,
    initialHistory,
    initialValues,
  });

  const { currentStep, history, context, animationType, animating, values } =
    state;

  if (currentStep === null) {
    throw new Error(
      `StepsOrchestrator is trying to render a step with a undefined Component ${JSON.stringify(
        { currentStep, history, context },
        null,
        2,
      )}.`,
    );
  }

  const { Component, name, valuesFromConfig } = currentStep;

  const isFirstStep = name === initialStep;

  logDebug(
    `StepsOrchestrator: ${name} isFirstStep: ${isFirstStep} animating: ${animating}`,
    {
      values,
      context,
    },
  );

  return (
    <>
      {withWizard && (
        <Wizard<Context, Step<Context>>
          config={config}
          activeStepName={name}
          context={context}
          CustomWizard={CustomWizard}
        />
      )}
      <StepsOrchestratorAnimation
        onAnimationEntered={handleAnimationEntered}
        animating={animating}
        animationType={animationType}
        name={name}
        parentPadding={parentPadding}
      >
        <StepOrchestratorProvider<Values, Context>
          values={{
            config,
            onSubmit: handleSubmit,
            valuesFromConfig,
            context: context as Context,
            jumpToStep: handleJumpToStep,
            nextStep: handleNextStep,
            previousStep: handlePreviousStep,
            values: values as Values,
            onClose,
            history,
            isFirstStep,
            valuesFromParent,
          }}
        >
          <Component
            config={config}
            onSubmit={handleSubmit}
            valuesFromConfig={valuesFromConfig}
            context={context}
            jumpToStep={handleJumpToStep}
            nextStep={handleNextStep}
            previousStep={handlePreviousStep}
            values={values}
            onClose={onClose}
            history={history}
            isFirstStep={isFirstStep}
            valuesFromParent={valuesFromParent}
          />
        </StepOrchestratorProvider>
      </StepsOrchestratorAnimation>
    </>
  );
};

export default StepsOrchestrator;
