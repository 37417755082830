import { EmojiSad } from 'iconoir-react';
import React, { FunctionComponent, ReactNode } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { Banner } from '../../atoms/Banners';
import { Heading } from '../../atoms/Heading';
import { SafeFormattedMessage } from '../../formatters';

import { $ErrorContainer, $ErrorSpacer } from './ErrorBoundary.styles';
import messages from './messages';

interface BaseErrorBoundaryProps {
  titleMessage?: string | MessageType;
  bodyMessage?: string | MessageType;
  isFullScreen?: boolean;
  icon?: ReactNode;
}

const BaseErrorBoundary: FunctionComponent<
  React.PropsWithChildren<BaseErrorBoundaryProps>
> = ({
  titleMessage = messages.title,
  bodyMessage = messages.didactic,
  icon = <EmojiSad width="80px" height="80px" color="#fff" />,
  isFullScreen = true,
  children,
}) => {
  return (
    <$ErrorContainer $isFull={isFullScreen}>
      <$ErrorSpacer animationDuration={2000}>{icon}</$ErrorSpacer>
      <$ErrorSpacer animationDuration={3000}>
        <Heading level={1}>{SafeFormattedMessage(titleMessage)}</Heading>
      </$ErrorSpacer>
      <$ErrorSpacer animationDuration={3000}>
        <Banner notificationType={NOTIFICATION_TYPES.INFO}>
          {SafeFormattedMessage(bodyMessage)}
        </Banner>
      </$ErrorSpacer>
      <$ErrorSpacer animationDelay={400} animationDuration={4000}>
        {children}
      </$ErrorSpacer>
    </$ErrorContainer>
  );
};

export default BaseErrorBoundary;
