import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}salesleads`;

interface UpsertSalesLeadCommandArgs {
  email: string;
  lastName: string;
  firstName: string;
  companyName: string;
  cName: string;
  phone: string;
  language: string;
}

const upsertSalesLeadCommand = async ({
  email,
  lastName,
  firstName,
  companyName,
  cName,
  phone,
  language,
}: UpsertSalesLeadCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        lastName,
        firstName,
        companyName,
        cName,
        phone,
        email,
        language,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default upsertSalesLeadCommand;
