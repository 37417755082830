import _ from 'lodash';
import { darken, desaturate, invert, lighten, saturate } from 'polished';

import controlDefaultTheme from './controlDefaultTheme';
import { CURRENT_CONFIGURED_THEME } from '@savgroup-front-common/constants/src/myaccount/theme';
import { SUPPORTED_THEMES } from '@savgroup-front-common/constants/src/shared';

import GridTheme from '../grid/gridTheme';
import { objectSubtract, rem } from '../helpers';

import defaultTheme from './defaultTheme';
import { carrefourTheme, truffautTheme } from './localClientsThemes';
import { newControlDefaultDarkTheme } from './newControlDefaultDarkTheme';
import { newControlDefaultTheme } from './newControlDefaultTheme';

const configMap = {
  [SUPPORTED_THEMES.DEFAULT]: null,
  [SUPPORTED_THEMES.CARREFOUR]: carrefourTheme,
  [SUPPORTED_THEMES.TRUFFAUT]: truffautTheme,
};

const applyRemOnlyIfValueContainsPx = (value: string) =>
  value.indexOf('px') >= 0 ? rem(value) : value;

const forwardStoryBookThemeValuesToTheme = (result: any, theme: any) => {
  return {
    ...result,
    colors: { ...result.colors, ..._.get(theme, 'colors', {}) },
    interactions: {
      ...result.interactions,
      ..._.get(theme, 'interactions', {}),
    },
    borders: { ...result.borders, ..._.get(theme, 'borders', {}) },
  };
};

export const getColorVariations = (
  color: string,
  isDarkModeEnabled = false,
) => {
  return {
    light1: lighten(0.052, saturate(0.21, color)),
    light2: lighten(0.147, desaturate(0.193, color)),
    light3: lighten(0.265, color),
    light4: lighten(0.3, color),
    light5: lighten(0.34, color),
    light6: lighten(0.35, color),
    light7: isDarkModeEnabled
      ? invert(lighten(0.368, color))
      : lighten(0.368, color),
    dark1: darken(0.08, desaturate(0.21, color)),
    dark2: darken(0.14, desaturate(0.28, color)),
  };
};

export const generateColorsNewUI = (theme: any, isDarkModeEnabled = false) => {
  if (
    !theme ||
    Object.keys(theme).length === 0 ||
    !theme.newUI ||
    !theme.newUI.defaultColors
  ) {
    return undefined;
  }

  return {
    ...theme.newUI,
    variationColors: getColorVariations(
      theme.colors.primary,
      isDarkModeEnabled,
    ),
  };
};

export const Theme = (
  theme: any = {},
  { forceTheme = false, storyBook = false } = {},
) => {
  let themeToBeConfigured = theme;
  const { hostname } = window.location;

  if (
    (hostname.includes('localhost') || hostname.includes('qualif')) &&
    !forceTheme
  ) {
    themeToBeConfigured = configMap[CURRENT_CONFIGURED_THEME] || theme;
  }

  let result = _.merge(
    {},
    defaultTheme,
    _.omit(GridTheme, ['components']),
    themeToBeConfigured,
  );

  const themeToBeConfiguredComponents = themeToBeConfigured?.components || {};

  for (const [componentName, componentConfig] of Object.entries(
    themeToBeConfiguredComponents,
  )) {
    for (const [propertyName, propertyValue] of Object.entries(
      componentConfig || {},
    )) {
      result = _.set(
        result,
        `components.${componentName}.${propertyName}`,
        propertyValue,
      );
    }
  }

  if (forceTheme) {
    result = forwardStoryBookThemeValuesToTheme(result, theme);
  }

  if (storyBook) {
    result._defaultTheme = objectSubtract(theme, defaultTheme);
  }

  result.borders.radius = applyRemOnlyIfValueContainsPx(result.borders.radius);
  result.borders.thicknessSpecialInput = applyRemOnlyIfValueContainsPx(
    result.borders.thicknessSpecialInput,
  );

  result = {
    ...result,
    newUI: {
      ...result.newUI,
      defaultColors: {
        heading:
          result.newUI?.defaultColors?.heading ??
          result.colors.headingTextColor,
        menu:
          result.newUI?.defaultColors?.menu ?? result.colors.headingTextColor,
        primary: result.newUI?.defaultColors?.primary ?? result.colors.primary,
        secondary:
          result.newUI?.defaultColors?.secondary ?? result.colors.secondary,
        text: result.newUI?.defaultColors?.text ?? result.colors.black,
        secondaryText:
          result.newUI?.defaultColors?.secondaryText ??
          result.colors.headerTextColor,
      },
    },
  };

  const newUI = generateColorsNewUI(result);

  if (newUI) {
    result.newUI = { defaultColors: result.newUI.defaultColors, ...newUI };
  }

  return result;
};

export const getControlTheme = (
  theme = {},
  isNewUiEnabled = false,
  isDarkModeEnabled = false,
) => {
  const newDefaultTheme = isDarkModeEnabled
    ? _.merge(newControlDefaultTheme, newControlDefaultDarkTheme)
    : newControlDefaultTheme;

  const result = _.merge(
    {},
    isNewUiEnabled ? newDefaultTheme : controlDefaultTheme,
    GridTheme,
    theme,
  );

  result.borders.radius = applyRemOnlyIfValueContainsPx(result.borders.radius);
  result.borders.thicknessSpecialInput = applyRemOnlyIfValueContainsPx(
    result.borders.thicknessSpecialInput,
  );

  const newUI = generateColorsNewUI(result, isDarkModeEnabled);

  if (newUI && result.newUI) {
    result.newUI = { defaultColors: result.newUI.defaultColors, ...newUI };
  }

  return result;
};

export const useGetControlTheme = (
  theme = {},
  isNewUiEnabled = false,
  isDarkModeEnabled = false,
) => {
  const newDefaultTheme = isDarkModeEnabled
    ? _.merge({}, newControlDefaultTheme, newControlDefaultDarkTheme)
    : newControlDefaultTheme;

  const result = _.merge(
    {},
    isNewUiEnabled ? newDefaultTheme : controlDefaultTheme,
    GridTheme,
    theme,
  );

  result.borders.radius = applyRemOnlyIfValueContainsPx(result.borders.radius);
  result.borders.thicknessSpecialInput = applyRemOnlyIfValueContainsPx(
    result.borders.thicknessSpecialInput,
  );

  const newUI = generateColorsNewUI(result, isDarkModeEnabled);

  if (newUI && result.newUI) {
    result.newUI = { defaultColors: result.newUI.defaultColors, ...newUI };
  }

  return result;
};

export const getIframeDefaultTheme = ({
  primaryColor,
  secondaryColor,
  headingTextColor,
}: any) => {
  return getControlTheme({
    colors: {
      ...defaultTheme.colors,
      primary: primaryColor || defaultTheme.colors.primary,
      secondary: secondaryColor || defaultTheme.colors.secondary,
      primaryHover: primaryColor || defaultTheme.colors.primary,
      headingTextColor:
        headingTextColor || defaultTheme.colors.headingTextColor,
    },
  });
};
