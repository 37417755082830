import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { LANGUAGES } from '@savgroup-front-common/constants';
import {
  BASE_LOADER_DIRECTION,
  BaseLoader,
} from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';

import CustomLoader from './CustomLoader';
import useGetSalesLeadOnboardingStatusQuery from './hooks/useGetSalesLeadOnboardingStatusQuery';
import useSalesLeadConfirmEmailCommand from './hooks/useSalesLeadConfirmEmailCommand';
import getMessagesList, { SET_UP_PROGRESS } from './OnboardingSetUp.helpers';
import { $Header, $SetUpContainer } from './OnboardingSetUp.styles';

const OnboardingSetUp = () => {
  const theme = useTheme();

  const defaultMessagesList = getMessagesList();
  const [messagesList, setMessagesList] = useState(defaultMessagesList);

  const handleMessagesList = useCallback((setUpProgress: SET_UP_PROGRESS) => {
    const messagesList = getMessagesList(setUpProgress);

    setMessagesList(messagesList);
  }, []);

  const { isFailed, isLoading } = useSalesLeadConfirmEmailCommand();

  useGetSalesLeadOnboardingStatusQuery({
    isLoading,
    isFailed,
    handleMessagesList,
  });

  return (
    <>
      <$Header>
        <a href="https://www.revers.io">
          <ReversioIcon color={theme.newUI.defaultColors.menu} />
        </a>
        <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
      </$Header>
      <$SetUpContainer>
        <BaseLoader
          customLoader={<CustomLoader />}
          size="78px"
          messageInterval={6000}
          messageList={messagesList}
          direction={BASE_LOADER_DIRECTION.COLUMN}
          isLargeFontSize
        />
      </$SetUpContainer>
    </>
  );
};

OnboardingSetUp.displayName = 'OnboardingSetUp';

export default OnboardingSetUp;
