import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { FormGroup, Input } from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import PostLabelTooltip from '../../../../atoms/PostLabelTooltip';

import useCustomerInformationName from './CustomerInformationName.hooks';
import messagesForm from './messages';

interface CustomerInformationNameProps {
  values: CreateClaimValues;
}

const CustomerInformationName: React.FC<CustomerInformationNameProps> = ({
  values,
}) => {
  const { formContext, onSubmit } = useCustomerInformationName({
    values,
  });

  const customerType = values.activatedSellerPartnersIdSelected?.customerType;
  const isBusiness = customerType === CUSTOMER_TYPE.BUSINESS;

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CUSTOMER_INFORMATION_NAME}
          isDisabled={!isValid}
        >
          <Row>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => {
                    return (
                      <Input
                        name={field.name}
                        value={field.value}
                        label={messagesForm.firstNameLabel}
                        disableBrowserAutocomplete
                        onChange={field.onChange}
                        errors={errors}
                        isRequired={!isBusiness}
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => {
                    return (
                      <Input
                        name={field.name}
                        value={field.value}
                        label={messagesForm.lastNameLabel}
                        disableBrowserAutocomplete
                        onChange={field.onChange}
                        errors={errors}
                        isRequired={!isBusiness}
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field }) => {
                    return (
                      <Input
                        name={field.name}
                        value={field.value}
                        label={messagesForm.companyNameLabel}
                        disableBrowserAutocomplete
                        onChange={field.onChange}
                        errors={errors}
                        isRequired={isBusiness}
                        postLabel={
                          <PostLabelTooltip>
                            <SafeFormattedMessageWithoutSpread
                              message={messagesForm.companyNameTooltip}
                            />
                          </PostLabelTooltip>
                        }
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => {
                    return (
                      <Input
                        name={field.name}
                        value={field.value}
                        label={messagesForm.emailLabel}
                        disableBrowserAutocomplete
                        onChange={field.onChange}
                        errors={errors}
                        isRequired
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => {
                    return (
                      <Input
                        name={field.name}
                        value={field.value}
                        label={messagesForm.phoneNumberLabel}
                        disableBrowserAutocomplete
                        onChange={field.onChange}
                        errors={errors}
                        isRequired
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

CustomerInformationName.displayName = 'CustomerInformationName';

export default CustomerInformationName;
