import React, { ChangeEvent, FunctionComponent, MouseEvent, Ref } from 'react';
import { useTheme } from 'styled-components';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import { CheckIcon, MinusIcon } from '../../protons/icons';

import { colorBasedOnProps } from './Checkbox.helpers';
import {
  $CheckboxInput,
  $CheckContainer,
  $MinusContainer,
  $StyledCheckbox,
} from './Checkbox.styles';

export interface CheckboxNakedProps {
  disabled?: boolean;
  danger?: boolean;
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onClick?: ((event: MouseEvent<HTMLInputElement>) => void) | undefined;
  checked?: boolean;
  isIndeterminate?: boolean;
  type?: 'checkbox' | 'radio';
  name: string;
  value?: string | undefined;
  dataTestId?: string;
  hasMultiple?: boolean;
  hasDescription?: boolean;
  isFlexAlign?: boolean;
  isNewUi?: boolean;
  isHidden?: boolean;
}

export interface CheckboxNakedWithRefProps extends CheckboxNakedProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const CheckboxNaked: FunctionComponent<
  React.PropsWithChildren<CheckboxNakedWithRefProps>
> = ({
  disabled = false,
  danger = false,
  type = 'checkbox',
  forwardedRef,
  name,
  onChange,
  checked,
  value,
  dataTestId,
  isIndeterminate,
  hasMultiple = false,
  hasDescription,
  isFlexAlign = false,
  isNewUi,
  isHidden = false,
  ...props
}) => {
  const theme = useTheme();
  const isNewUiEnabled = useIsNewUiEnabled() || isNewUi;

  return (
    <>
      <$CheckboxInput
        {...props}
        onChange={onChange}
        isIndeterminate={isIndeterminate}
        checked={checked}
        type={type}
        role={type}
        name={name}
        id={name}
        disabled={disabled}
        ref={forwardedRef}
        danger={danger}
        value={value}
        data-testid={dataTestId}
        multiple={hasMultiple}
        $isNewUiEnabled={isNewUiEnabled}
        $isHidden={isHidden}
      />
      <$StyledCheckbox
        danger={danger}
        disabled={disabled}
        $hasDescription={hasDescription}
        $isFlexAlign={isFlexAlign}
        $isHidden={isHidden}
      >
        <$CheckContainer>
          {!isIndeterminate && (
            <CheckIcon color={theme.colors.black} size="18px" />
          )}
          {isIndeterminate && (
            <MinusIcon color={theme.colors.black} size="18px" />
          )}
        </$CheckContainer>
        {!disabled && (
          <$MinusContainer>
            <MinusIcon
              color={colorBasedOnProps({ theme, disabled, danger })}
              size="18px"
            />
          </$MinusContainer>
        )}
      </$StyledCheckbox>
    </>
  );
};

export default React.forwardRef<HTMLInputElement, CheckboxNakedProps>(
  (props, ref) => <CheckboxNaked forwardedRef={ref} {...props} />,
);
