import { format } from 'date-fns';

import {
  COUNTRY_CODES,
  CURRENCIES,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import { formatAmountToNumber } from '@savgroup-front-common/core/src/formatters';
import {
  CUSTOMER_TYPE,
  OwnerSummary,
  SalesChannel,
} from '@savgroup-front-common/types';

import { CreateOrderAndShareLinkPayload } from '../../../../api/Owner/createOrderAndShareLink';
import { MinimalSeller } from '../../../../types';

export interface SearchModel {
  brandLabel: string;
  modelType: string;
  label: string;
  sku: string;
  id?: string;
}

interface PrepareAuthenticateWithProductReferencePayloadArgs {
  tenantId: string;
  sellerId: string;
  customerType: CUSTOMER_TYPE;
  data: {
    customerInformation: Partial<OwnerSummary>;
    productReference: {
      model?: SearchModel;
      seller?: MinimalSeller;
      salesChannel?: SalesChannel;
      purchaseDate?: Date;
      productPrice?: number;
    };
  };
}

const prepareAuthenticateWithProductReferencePayload = ({
  tenantId,
  sellerId,
  customerType,
  data,
}: PrepareAuthenticateWithProductReferencePayloadArgs): CreateOrderAndShareLinkPayload => {
  const { customerInformation } = data;

  return {
    tenantId,
    payload: {
      civility: customerInformation.civility,
      product: {
        price: {
          amount: data?.productReference?.productPrice
            ? formatAmountToNumber(
                data?.productReference?.productPrice,
              ).toString()
            : '',
          currency: CURRENCIES.EUR,
        },
        modelType: data?.productReference?.model?.modelType,
        brandName: data?.productReference?.model?.brandLabel,
        sku: data?.productReference?.model?.sku,
        label: data?.productReference?.model?.label,
        id: data.productReference.model?.id,
      },
      address: {
        additionalAddress: customerInformation?.ownerAddress?.additionalAddress,
        address: customerInformation?.ownerAddress?.address || '',
        addressId: undefined,
        additionalInformation: undefined,
        city: customerInformation?.ownerAddress?.city || '',
        civility: customerInformation.civility,
        companyName: customerInformation.companyName,
        countryCode: customerInformation?.ownerAddress
          ?.countryCode as COUNTRY_CODES,
        doorCode: undefined,
        floor: undefined,
        firstname: customerInformation.firstName || '',
        isDefault: true,
        lastname: customerInformation.lastName || '',
        phone: customerInformation.phone,
        postalCode: customerInformation?.ownerAddress?.postalCode || '',
      },
      customerFirstName: customerInformation.firstName || '',
      customerLastName: customerInformation.lastName || '',
      customerMail: customerInformation.email || '',
      isOutOfWarranty: false,
      phoneNumber: customerInformation.phone,
      purchaseDateUtc: data?.productReference?.purchaseDate
        ? format(
            data?.productReference?.purchaseDate,
            STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          )
        : undefined,
      salesChannelId: data?.productReference?.salesChannel?.id,
      salesChannelType: data?.productReference?.salesChannel?.salesChannelType,
      sellerId,
      customerType,
    },
  };
};

export default prepareAuthenticateWithProductReferencePayload;
