import * as Yup from 'yup';

import {
  globalMessages,
  isEmail,
} from '@savgroup-front-common/core/src/helpers';

const CustomerInformationNameSchema = ({
  isBusiness,
}: {
  isBusiness: boolean;
}) => {
  return Yup.object().shape({
    firstName: Yup.string().when('$isRequired', {
      is: !isBusiness,
      then: Yup.string().required(globalMessages.form.required),
      otherwise: Yup.string(),
    }),
    lastName: Yup.string().when('$isRequired', {
      is: !isBusiness,
      then: Yup.string().required(globalMessages.form.required),
      otherwise: Yup.string(),
    }),
    companyName: Yup.string(),
    email: Yup.string()
      .required(globalMessages.form.required)
      .test('is-email', globalMessages.form.invalidEmail, (value) => {
        return isEmail(value);
      }),
    phoneNumber: Yup.string().required(globalMessages.form.required),
  });
};

export default CustomerInformationNameSchema;
