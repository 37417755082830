import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  Autocomplete,
  FormGroup,
  Input,
  PlacesAutocomplete,
} from '@savgroup-front-common/core/src/atoms/Form';
import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import {
  CustomerInformationAddressValues,
  HandleSelectAddressValues,
} from './CustomerInformationAddress.types';
import messagesForm from './messages';

interface CustomerInformationAddressProps {
  formContext: UseFormReturn<CustomerInformationAddressValues>;
  countryOptions: BaseAutocompleteOption[];
  handleSelectAddress: (address: HandleSelectAddressValues) => void;
}

const CustomerInformationAddressContent: React.FC<
  CustomerInformationAddressProps
> = ({ formContext, countryOptions, handleSelectAddress }) => {
  const {
    formState: { errors },
    control,
    watch,
  } = formContext;

  const watchedCountryCode = watch('countryCode');

  return (
    <>
      <Row>
        <Col sm={12} xs={12}>
          <FormGroup>
            <Controller
              control={control}
              name="address"
              render={({ field }) => {
                return (
                  <PlacesAutocomplete
                    name={field.name}
                    value={field.value}
                    label={messagesForm.addressLabel}
                    errors={errors}
                    isRequired
                    onSelect={handleSelectAddress}
                    onChange={field.onChange}
                    countryCode={watchedCountryCode?.value}
                    dataTestId="CustomerInformations-informations-address"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          <FormGroup>
            <Controller
              control={control}
              name="additionalAddress"
              render={({ field }) => {
                return (
                  <Input
                    name={field.name}
                    value={field.value}
                    label={messagesForm.additionalAddressLabel}
                    disableBrowserAutocomplete
                    onChange={field.onChange}
                    errors={errors}
                    dataTestId="CustomerInformations-informations-additionalAddress"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={4} xs={12}>
          <FormGroup>
            <Controller
              control={control}
              name="postalCode"
              render={({ field }) => {
                return (
                  <Input
                    name={field.name}
                    value={field.value}
                    label={messagesForm.postalCodeLabel}
                    disableBrowserAutocomplete
                    onChange={field.onChange}
                    errors={errors}
                    isRequired
                    dataTestId="CustomerInformations-informations-postalCode"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col sm={4} xs={12}>
          <FormGroup>
            <Controller
              control={control}
              name="city"
              render={({ field }) => {
                return (
                  <Input
                    name={field.name}
                    value={field.value}
                    label={messagesForm.cityLabel}
                    disableBrowserAutocomplete
                    onChange={field.onChange}
                    errors={errors}
                    isRequired
                    dataTestId="CustomerInformations-informations-city"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col sm={4} xs={12}>
          <FormGroup>
            <Controller
              control={control}
              name="countryCode"
              render={({ field }) => {
                return (
                  <Autocomplete
                    name={field.name}
                    value={field.value}
                    label={messagesForm.countryLabel}
                    onChange={field.onChange}
                    options={countryOptions}
                    errors={errors}
                    isRequired
                    dataTestId="CustomerInformations-informations-country"
                  />
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default CustomerInformationAddressContent;
