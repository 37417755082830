import React, { FunctionComponent, useContext } from 'react';

import { MultiBanners } from '../../../atoms/Banners';

import BannersProviderContext from './BannersProvider.context';

interface BannersConsumer {
  isFluid?: boolean;
  hasClosedOption?: boolean;
}

const BannersConsumer: FunctionComponent<BannersConsumer> = ({
  isFluid,
  hasClosedOption = true,
}) => {
  const { notifications, removeNotification } = useContext(
    BannersProviderContext,
  );

  return (
    <MultiBanners
      isFluid={isFluid}
      notifications={
        hasClosedOption
          ? notifications.map((notification) => ({
              ...notification,
              onClose: () => removeNotification(notification.id),
            }))
          : notifications
      }
    />
  );
};

export default BannersConsumer;
