import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $Form = styled.form`
  padding: 0 72px;

  @media ${media.maxWidth.sm} {
    padding: 0;
  }
`;

export const $SubmitButton = styled(Button)`
  margin-top: 24px;
`;

export const $InputRow = styled.div`
  margin-bottom: 18px;
`;

export const $Row = styled(Row)`
  margin-bottom: 18px;
`;

export const $Preview = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.megaSmall};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
  margin-bottom: 8px;
`;

export const $BannerMessage1 = styled.div`
  display: inline;
`;

export const $Bold = styled.div`
  display: inline;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $BannerContainer = styled.div`
  margin-bottom: 8px;

  > div {
    margin: 0;
    max-width: fit-content;
  }
`;

export const $MainDomainName = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.newUI.variationColors.dark1};
`;

export const $DomainName = styled.div`
  display: inline-flex;
`;
