import { transparentize } from 'polished';
import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  getCustomComponentProperty,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import {
  LOGO_POSITION,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

interface HeaderContainerProps {
  componentThemeName: string;
}

export const $HeaderContainer = styled('div')<HeaderContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  background-color: ${({ theme, componentThemeName }) => {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-bottom: ${({ theme }) =>
    `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`};
  margin-bottom: 1rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
`;

export const $MenuContainer = styled.div<{ $position: LOGO_POSITION }>`
  position: relative;
  align-items: center;
  height: ${rem(64)};
  display: flex;

  flex-direction: ${({ $position }) => {
    if ($position === LOGO_POSITION.RIGHT) {
      return 'row-reverse';
    }

    return 'row';
  }};

  justify-content: space-between;
`;

interface LogoContainerProps {
  componentThemeName: string;
  $isMobileView?: boolean;
}

export const $LogoContainer = styled('div')<LogoContainerProps>`
  display: block;
  width: 100%;
  height: 45px;
  cursor: pointer;
  padding: ${({ componentThemeName, theme }) => {
    const themeValue = getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
    });

    return themeValue || '0px 0px';
  }};
  position: ${({ componentThemeName, theme, $isMobileView }) => {
    if ($isMobileView) {
      return 'relative';
    }

    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.POSITION,
      fallback: 'relative',
    });
  }};
  text-align: ${({ componentThemeName, theme, $isMobileView }) => {
    if ($isMobileView) {
      return 'left';
    }

    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.TEXT_ALIGN,
      fallback: 'left',
    });
  }};
`;

export const $LogoImg = styled('img')`
  height: 100%;
  max-height: 100%;
  max-width: 100%;
`;

export const $LogoutButton = styled(Button)`
  @media (max-width: 670px) {
    display: none;
  }
`;

export const $SmallLogoutButton = styled(Button)`
  display: none;
  @media (max-width: 670px) {
    display: block;
    width: initial;
    height: initial;
    line-height: 0;

    & > span {
      height: 100%;
    }
  }
`;

export const $ButtonCol = styled(Col)`
  @media (min-width: 670px) {
    display: flex !important;
    align-items: center !important;
  }
  @media (max-width: 670px) {
    padding: 0;
  }
`;

export const $HeaderCol = styled(Col)`
  @media (max-width: 670px) {
    max-width: 40%;
    flex-basis: 40%;
  }
`;

export const $LogoCol = styled(Col)`
  @media (max-width: 670px) {
    max-width: 60%;
    flex-basis: 60%;
  }
`;

export const $RightSide = styled.div`
  display: flex;
`;
