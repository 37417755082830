import React, { FunctionComponent, MouseEvent, ReactElement } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useMeasure } from 'react-use';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src/shared';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import {
  CheckCircleIcon,
  CrossCircleIcon,
  CrossIcon,
  InformationPlainIcon,
} from '../../protons/icons';
import WarningTriangle from '../../protons/icons/WarningTriangle/WarningTriangle.icon';

import {
  $CloseContainer,
  $IconContainer,
  $NotificationContainer,
  $NotificationWrapper,
  $TextContainer,
  colorBasedOnPropsIncludingHollow,
  iconColorBasedOnProps,
} from './Banner.styles';

interface BannerTheming {
  hollow?: boolean;
  notificationType?: NOTIFICATION_TYPES;
}

interface IconProps {
  color: string;
  size?: string;
  notificationType?: NOTIFICATION_TYPES;
  icon?: ReactElement;
}

const Icon: FunctionComponent<IconProps> = ({
  notificationType,
  color,
  size,
  icon,
}) => {
  if (icon) {
    return icon;
  }
  if (notificationType === NOTIFICATION_TYPES.SUCCESS) {
    return <CheckCircleIcon color={color} size={size} />;
  }
  if (notificationType === NOTIFICATION_TYPES.ALERT) {
    return <WarningTriangle color={color} size={size} />;
  }
  if (notificationType === NOTIFICATION_TYPES.ERROR) {
    return <CrossCircleIcon color={color} size={size} />;
  }
  if (notificationType === NOTIFICATION_TYPES.DEFAULT) {
    return <InformationPlainIcon color={color} size={size} />;
  }

  return <InformationPlainIcon color={color} size={size} />;
};

Icon.displayName = 'Icon';

export interface BannerBaseProps extends BannerTheming {
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void | undefined;
  hasIcon?: boolean;
  animationDuration?: number;
  dataTestId?: string;
  isFluid?: boolean;
  isNewUi?: boolean;
  icon?: ReactElement;
}
interface BannerBaseWithAnimationProps extends BannerBaseProps {
  animationState: TransitionStatus;
}

const BannerBaseWithAnimation: FunctionComponent<
  React.PropsWithChildren<BannerBaseWithAnimationProps>
> = ({
  onClose = undefined,
  notificationType,
  hollow = false,
  hasIcon = true,
  children,
  animationDuration = 300,
  animationState,
  dataTestId = 'notification',
  isFluid = false,
  icon,
  isNewUi = false,
}) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const isNewUiEnabled = useIsNewUiEnabled() || isNewUi;

  const color = colorBasedOnPropsIncludingHollow({
    theme,
    notificationType,
    hollow,
  });

  return (
    <$NotificationContainer $height={height} animationState={animationState}>
      <$NotificationWrapper
        $isFluid={isFluid}
        $isNewUiEnabled={isNewUiEnabled}
        animationState={animationState}
        animationDuration={animationDuration}
        ref={ref}
        hollow={hollow}
        notificationType={notificationType}
        data-testid={dataTestId}
      >
        {hasIcon && (
          <$IconContainer $isNewUiEnabled={isNewUiEnabled}>
            <Icon
              color={
                isNewUiEnabled
                  ? iconColorBasedOnProps({ theme, notificationType })
                  : color
              }
              notificationType={notificationType}
              icon={icon}
            />
          </$IconContainer>
        )}
        <$TextContainer $hasIcon={hasIcon}>{children}</$TextContainer>
        {!!onClose && (
          <$CloseContainer
            onClick={onClose}
            type={BUTTON_TYPES.BUTTON}
            hollow={hollow}
            notificationType={notificationType}
          >
            <CrossIcon
              color={
                isNewUiEnabled
                  ? iconColorBasedOnProps({ theme, notificationType })
                  : color
              }
            />
          </$CloseContainer>
        )}
      </$NotificationWrapper>
    </$NotificationContainer>
  );
};

export default BannerBaseWithAnimation;
