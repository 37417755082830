import React from 'react';
import { FormProvider } from 'react-hook-form';

import { CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import SuggestedAddressModal from './components/SuggestedAddressModal';
import useCustomerInformationAddressForm from './CustomerInformationAddress.hooks';
import CustomerInformationAddressContent from './CustomerInformationAddressContent';
import useHandleSelectAddress from './hooks/useHandleSelectAddress';

interface CustomerInformationAddressProps {
  values: CreateClaimValues;
}

const CustomerInformationAddress: React.FC<CustomerInformationAddressProps> = ({
  values,
}) => {
  const {
    formContext,
    isLoading,
    onSubmit,
    isOpenModal,
    handleCloseSuggestedAddressModal,
    addressEntered,
    suggestedAddress,
  } = useCustomerInformationAddressForm({
    values,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formContext;

  const { handleSelectAddress, countryOptions } = useHandleSelectAddress({
    formContext,
  });

  const hasAddress = addressEntered && suggestedAddress;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CUSTOMER_INFORMATION_ADDRESS}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <CustomerInformationAddressContent
            formContext={formContext}
            countryOptions={countryOptions}
            handleSelectAddress={handleSelectAddress}
          />
        </StepLayout>
      </form>

      {hasAddress && (
        <SuggestedAddressModal
          values={values}
          isOpen={isOpenModal}
          onClose={handleCloseSuggestedAddressModal}
          addressEntered={addressEntered}
          suggestedAddress={suggestedAddress}
        />
      )}
    </FormProvider>
  );
};

export default CustomerInformationAddress;
