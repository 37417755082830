import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $ActivitiesContainer = styled.div`
  padding: 24px 208px 32px 208px;

  @media ${media.maxWidth.md} {
    padding: 24px 104px 32px 104px;
  }

  @media ${media.maxWidth.sm} {
    padding: 24px;
  }
`;
