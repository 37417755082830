import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';

import { LANGUAGES } from '@savgroup-front-common/constants';
import WizardNewUi from '@savgroup-front-common/core/src/atoms/wizard/customWizard/WizardNewUi/WizardNewUi';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';

import { $Header } from '../OnboardingSetUp/OnboardingSetUp.styles';

import useSavePersonalizedDemoContextCommand from './hooks/useSavePersonalizedDemoContextCommand';
import {
  ACTIVITIES_STEPS,
  ACTIVITIES_STEPS_NAMES,
} from './OnboardingActivities.steps';
import { $ActivitiesContainer } from './OnboardingActivities.styles';
import { OnboardingActivitiesSubmitValues } from './OnboardingActivities.types';

const OnboardingActivities: React.FC = () => {
  const theme = useTheme();
  const { handleSubmitSalesLead, isLoading } =
    useSavePersonalizedDemoContextCommand();

  const handleSubmit = useCallback(
    (value: OnboardingActivitiesSubmitValues) => {
      handleSubmitSalesLead(value);
    },
    [handleSubmitSalesLead],
  );

  return (
    <>
      <$Header>
        <a href="https://www.revers.io">
          <ReversioIcon color={theme.newUI.defaultColors.menu} />
        </a>
        <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
      </$Header>
      <$ActivitiesContainer>
        <StepsOrchestrator<OnboardingActivitiesSubmitValues>
          initialStep={ACTIVITIES_STEPS_NAMES.DOMAINS_PAGE}
          config={ACTIVITIES_STEPS}
          onSubmit={handleSubmit}
          CustomWizard={WizardNewUi}
          valuesFromParent={{ isLoading }}
        />
      </$ActivitiesContainer>
    </>
  );
};

OnboardingActivities.displayName = 'OnboardingActivities';

export default OnboardingActivities;
