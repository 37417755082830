import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $ConfirmationContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  padding: 24px 88px;
`;

export const $ConfirmationBody = styled.div`
  height: 91%;
  padding: 48px 272px 0 272px;
  background-color: ${({ theme }) => theme.newUI.variationColors?.light5};
  @media ${media.maxWidth.md} {
    padding: 104px;
  }

  @media ${media.maxWidth.sm} {
    padding: 24px;
  }
`;

export const $IconWithTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: column;
  gap: 16px;
`;

export const $AccountActivationTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.big};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $VerificationMessage = styled.div`
  margin-bottom: 32px;
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const $EmailActivationMessage = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  display: flex;
  text-align: center;
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
  justify-content: center;
`;

export const $SpamMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
`;

export const $ResendButton = styled(Button)`
  margin-top: 24px;
`;

export const $UserEmailValue = styled.div`
  color: ${({ theme }) => theme.newUI.defaultColors.primary};
  display: inline;
`;
