import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import {
  Autocomplete,
  Datepicker,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  CreateClaimContext,
  CreateClaimValues,
  SELLER_TYPE,
} from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import PostLabelTooltip from '../../../../atoms/PostLabelTooltip';
import messages from '../../messages';

import useChoicePartner from './ChoicePartnerStore.hooks';
import useGetSalesChannelOptions from './hooks/useGetSalesChannelOptions';
import messageForm from './messages';

interface ChoicePartnerStoreProps {
  context: CreateClaimContext;
  values: CreateClaimValues;
}

const ChoicePartnerStore: React.FC<ChoicePartnerStoreProps> = ({ values }) => {
  const {
    formContext,
    onSubmit,
    errors,
    isLoading,
    partnerSelected,
    sellerType,
  } = useChoicePartner({
    values,
  });

  const { control, watch } = formContext;

  const choicePartnerPurchaseDateWatched = watch('choicePartnerPurchaseDate');
  const choicePartnerPurchaseStoreWatched = watch('choicePartnerPurchaseStore');

  const isValid =
    choicePartnerPurchaseDateWatched && choicePartnerPurchaseStoreWatched;

  const { salesChannelOptions, isLoading: isSalesChannelLoading } =
    useGetSalesChannelOptions({
      sellerId: partnerSelected?.sellerId,
    });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CHOICE_PARTNER_STORE}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <FormGroup>
            <Controller
              control={control}
              name="choicePartnerPurchaseDate"
              render={({ field }) => {
                return (
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        <Datepicker
                          ref={field.ref}
                          name={field.name}
                          label={messageForm.purchaseDate}
                          errors={errors}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          value={field.value}
                          postLabel={
                            <PostLabelTooltip>
                              <SafeFormattedMessageWithoutSpread
                                message={messageForm.purchaseDateTooltip}
                              />
                            </PostLabelTooltip>
                          }
                          maxDate={new Date()}
                          isRequired
                        />
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="choicePartnerPurchaseStore"
              render={({ field }) => {
                return (
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        <Autocomplete
                          ref={field.ref}
                          name={field.name}
                          label={
                            sellerType !== SELLER_TYPE.RETAILER
                              ? messageForm.salesChannelWithoutRetailer
                              : messageForm.salesChannel
                          }
                          errors={errors}
                          options={salesChannelOptions}
                          onChange={field.onChange}
                          isLoading={isSalesChannelLoading}
                          value={field.value}
                          placeholder={
                            sellerType !== SELLER_TYPE.RETAILER
                              ? messages.choicePartnerPurchaseStore
                              : messages.choiceProductGenericModelFindMyProductCategory
                          }
                          postLabel={
                            <PostLabelTooltip>
                              <SafeFormattedMessageWithoutSpread
                                message={
                                  sellerType !== SELLER_TYPE.RETAILER
                                    ? messageForm.salesChannelTooltipWithoutRetailer
                                    : messageForm.salesChannelTooltip
                                }
                              />
                            </PostLabelTooltip>
                          }
                          tabIndex={0}
                          isRequired
                        />
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            />
          </FormGroup>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ChoicePartnerStore.displayName = 'ChoicePartnerStore';

export default ChoicePartnerStore;
