import { transparentize } from 'polished';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import {
  getCustomComponentProperty,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

export const $LayoutGrid = styled(Grid)`
  padding: 1rem;
`;

export const $ColContainer = styled(Col)`
  margin-top: 'unset';
  @media ${media.minWidth.sm} {
    margin-top: ${rem(80)};
  }
  position: relative;
  min-height: calc(100vh - 158px);
  margin-bottom: ${rem(100)};
`;

export const $StepContainer = styled('div')``;

export const $Footer = styled.footer`
  width: 100%;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  background-color: ${({ theme }) => {
    return getCustomComponentProperty({
      theme,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-top: ${({ theme }) =>
    `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`};
  margin-top: 1rem;
  box-shadow: 0px -2px 10px rgba(104, 154, 186, 0.1);
`;

interface RowFooterProps {
  isFirstStep: boolean;
}

export const $RowFooter = styled(Row)<RowFooterProps>`
  display: flex;
  width: 100%;
  justify-content: ${({ isFirstStep }) =>
    isFirstStep ? 'right' : 'space-between'};
  margin-right: unset;
  margin-left: unset;
`;

export const $Banner = styled('img')`
  @media ${media.minWidth.sm} {
    margin-top: 64px;
  }
  margin-top: unset;
`;
