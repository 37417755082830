import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { LANGUAGES } from '@savgroup-front-common/constants/src/shared';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { ClientService } from 'authenticator/api';
import { AuthConfiguration } from 'authenticator/configuration';
import { ClientInformation } from 'authenticator/types';

export const GET_CLIENT_INFORMATION = 'getClientInformation';

interface UseGetClientInformationReturnValues {
  clientInformation?: ClientInformation;
  sellerPartners?: {
    sellerId: string;
    sellerName: string;
    customerType?: CUSTOMER_TYPE;
  }[];
  currentLanguage: LANGUAGES;
  isLoading: boolean;
}

const useGetClientInformation = (): UseGetClientInformationReturnValues => {
  const { clientId } = AuthConfiguration;
  const currentLanguage = useSelector(selectLocale);

  const { data, isLoading } = useQuery(
    [
      GET_CLIENT_INFORMATION,
      {
        clientId,
        currentLanguage,
      },
    ],
    async () => {
      if (!clientId) {
        return undefined;
      }
      const responseClientInformation =
        await ClientService.getClientInformations({
          clientId,
        });
      const responseSellerPartners = await ClientService.getSellerPartners({
        clientId,
      });

      if (responseClientInformation.failure || responseSellerPartners.failure) {
        logError(
          `Got an empty getClientInformation for clientId: ${clientId}.`,
        );

        return undefined;
      }

      return {
        clientInformation: responseClientInformation.value,
        sellerPartners: responseSellerPartners.value,
      };
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    clientInformation: data?.clientInformation,
    sellerPartners: data?.sellerPartners,
    currentLanguage: currentLanguage as LANGUAGES,
    isLoading,
  };
};

export default useGetClientInformation;
