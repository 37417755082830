import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { AuthConfiguration } from 'authenticator/configuration';

const CLIENT_ID = 'CLIENT_ID';

const ENDPOINT = `${AuthConfiguration.authority}/api/client/sellerPartners?clientId=${CLIENT_ID}`;

interface GetSellerPartnersAgrs {
  clientId: string;
}

const getSellerPartners = async ({
  clientId,
}: GetSellerPartnersAgrs): Promise<
  | BackResponse<
      {
        sellerId: string;
        sellerName: string;
        customerType?: CUSTOMER_TYPE;
      }[]
    >
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(CLIENT_ID, clientId);

    return await apiCall<
      BackResponse<
        {
          sellerId: string;
          sellerName: string;
          customerType?: CUSTOMER_TYPE;
        }[]
      >
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellerPartners;
