import 'array-flat-polyfill';
import 'polyfill-object.fromentries';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ToastContainer } from '@savgroup-front-common/core/src/atoms/Toast';
import { ConfirmationContextProvider } from '@savgroup-front-common/core/src/components/Confirmation/ConfirmationContextProvider';
import { HotjarProvider } from '@savgroup-front-common/core/src/components/HotjarProvider';
import IntlManager from '@savgroup-front-common/core/src/components/IntlManager/IntlManager';
import { NewUiProvider } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { GlobalStyle } from '@savgroup-front-common/core/src/helpers/globalStyle';
import useAppendNoIndexMeta from '@savgroup-front-common/core/src/hooks/useAppendNoIndexMeta';
import {
  BannersProvider,
  ToastsConsumer,
  ToastsProvider,
} from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';
import { getControlTheme } from '@savgroup-front-common/core/src/theme/Theme';

import HOTJAR_CONFIG from '../../configuration/hotjar';
import useInitGoogleMap from '../../hooks/useInitGoogleMap';
import configureStore from '../../store/configureStore';
import ClientInformationProvider from '../atoms/ClientInformationProvider';

import MyAccountThemeContainer from './MyAccountThemeContainer';
import { MyAccountRoutes } from './Routes/MyAccountRoutes';
import { OnboardingRoutes } from './Routes/OnboardingRoutes';
import useIsOnboardingEnabled from './Routes/useIsOnboardingEnabled';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  try {
    useInitGoogleMap();

    const store = configureStore();
    const isOnboardingEnabled = useIsOnboardingEnabled();

    useAppendNoIndexMeta({
      VITE_ENV: import.meta.env.VITE_ENV as string,
    });

    return (
      <HotjarProvider config={HOTJAR_CONFIG}>
        <ToastsProvider>
          <BrowserRouter>
            {isOnboardingEnabled && (
              <NewUiProvider forcedIsNewUiEnabled={true}>
                <ReduxProvider store={store}>
                  <QueryClientProvider client={queryClient}>
                    <BannersProvider>
                      <ThemeProvider theme={getControlTheme({}, true)}>
                        <IntlManager>
                          <ReactQueryDevtools initialIsOpen={false} />

                          <ConfirmationContextProvider>
                            <GlobalStyle myAccount isNewUiEnabled />
                            <ExternalPortal>
                              <ToastContainer topOffset="6rem" />
                            </ExternalPortal>
                            <ToastsConsumer />
                            <OnboardingRoutes />
                          </ConfirmationContextProvider>
                        </IntlManager>
                      </ThemeProvider>
                    </BannersProvider>
                  </QueryClientProvider>
                </ReduxProvider>
              </NewUiProvider>
            )}

            {!isOnboardingEnabled && (
              <NewUiProvider forcedIsNewUiEnabled={true}>
                <ReduxProvider store={store}>
                  <QueryClientProvider client={queryClient}>
                    <BannersProvider>
                      <ClientInformationProvider>
                        <MyAccountThemeContainer>
                          <IntlManager>
                            <ReactQueryDevtools initialIsOpen={false} />

                            <ConfirmationContextProvider>
                              <GlobalStyle myAccount />
                              <ExternalPortal>
                                <ToastContainer topOffset="6rem" />
                              </ExternalPortal>
                              <ToastsConsumer />

                              <MyAccountRoutes />
                            </ConfirmationContextProvider>
                          </IntlManager>
                        </MyAccountThemeContainer>
                      </ClientInformationProvider>
                    </BannersProvider>
                  </QueryClientProvider>
                </ReduxProvider>
              </NewUiProvider>
            )}
          </BrowserRouter>
        </ToastsProvider>
      </HotjarProvider>
    );
  } catch (err) {
    logCritical(err);
    throw err;
  }
};

export default App;
