import { defineMessages } from 'react-intl';

export default defineMessages({
  lastName: {
    id: 'authenticator.onboarding.form.lastNameLabel',
    defaultMessage: 'Last name',
  },
  firstName: {
    id: 'authenticator.onboarding.form.firstNameLabel',
    defaultMessage: 'First name',
  },
  companyName: {
    id: 'authenticator.onboarding.form.companyNameLabel',
    defaultMessage: 'Company name',
  },
  email: {
    id: 'authenticator.onboarding.form.emailLabel',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'authenticator.onboarding.form.phoneLabel',
    defaultMessage: 'Phone',
  },
  country: {
    id: 'authenticator.onboarding.form.country',
    defaultMessage: 'Country',
  },
  trialStart: {
    id: 'authenticator.onboarding.form.trialStart',
    defaultMessage: 'I start my trial',
  },
  accoundCreationSuccess: {
    id: 'authenticator.onboarding.form.accoundCreationSuccess',
    defaultMessage: 'Account created successfully',
  },
  accoundCreationError: {
    id: 'authenticator.onboarding.form.accoundCreationError',
    defaultMessage: 'Account creation failed',
  },
  cgvRequired: {
    id: 'authenticator.onboarding.form.cgvRequired',
    defaultMessage: 'You must accept the general conditions of use',
  },
  invalidEmail: {
    id: 'authenticator.onboarding.form.invalidEmail',
    defaultMessage: 'This email is invalid, please use an other one',
  },
  cName: {
    id: 'authenticator.onboarding.form.cNameLabel',
    defaultMessage: 'Domain name',
  },
  preview: {
    id: 'authenticator.onboarding.form.preview',
    defaultMessage: 'Preview : {domainName}',
  },
  cNameAlreadyUsed: {
    id: 'authenticator.onboarding.form.cNameAlreadyUsed',
    defaultMessage: 'This domain name is already used',
  },
  bannerMessageBold1: {
    id: 'authenticator.onboarding.form.bannerMessageBold1',
    defaultMessage: 'Your domain name',
  },
  bannerMessage1: {
    id: 'authenticator.onboarding.form.bannerMessage1',
    defaultMessage: 'is the name of your space',
  },
  bannerMessage2: {
    id: 'authenticator.onboarding.form.bannerMessage2',
    defaultMessage: 'Once created, it cannot be changed',
  },
});
