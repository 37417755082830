import React, { FunctionComponent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { MessageType } from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../../../formatters';
import { useIsNewUiEnabled } from '../../../../hooks/useIsNewUiEnabled';
import { FIELD_STATUS, InputStatus } from '../helpers/getFinalFieldState.types';

import {
  $AsteriskSpan,
  $LabelContainer,
  $LabelStyled,
  $LabelWrap,
  $SubLabel,
} from './Label.styles';

interface LabelProps {
  required?: boolean;
  status?: InputStatus;
  isRequired?: boolean;
  isError?: boolean;
  htmlFor?: string;
  postLabel?: ReactNode;
  subLabel?: MessageDescriptor | MessageType | string | number;
  fullWidth?: boolean;
  isHorizontal?: boolean;
}

export const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
  children,
  status = null,
  required = false,
  isRequired = false,
  isError = false,
  htmlFor,
  postLabel,
  subLabel,
  fullWidth,
  isHorizontal = false,
  ...rest
}) => {
  const transitionRequired = required || isRequired;
  const isNewUiEnabled = useIsNewUiEnabled();

  if (subLabel) {
    return (
      <$LabelContainer>
        <$LabelWrap fullWidth={fullWidth} isHorizontal={isHorizontal}>
          <$LabelStyled
            isError={isError || status === FIELD_STATUS.ERROR}
            htmlFor={htmlFor}
            $isNewUi={isNewUiEnabled}
            isHorizontal={isHorizontal}
            {...rest}
          >
            {children}
            {transitionRequired && <$AsteriskSpan>*</$AsteriskSpan>}
          </$LabelStyled>
          {postLabel}
        </$LabelWrap>
        <$SubLabel title={safeFormattedIntlString(subLabel)}>
          <SafeFormattedMessageWithoutSpread message={subLabel} />
        </$SubLabel>
      </$LabelContainer>
    );
  }

  return (
    <$LabelWrap fullWidth={fullWidth} isHorizontal={isHorizontal}>
      <$LabelStyled
        isError={isError || status === FIELD_STATUS.ERROR}
        htmlFor={htmlFor}
        $isNewUi={isNewUiEnabled}
        isHorizontal={isHorizontal}
        {...rest}
      >
        {children}
        {transitionRequired && <$AsteriskSpan>*</$AsteriskSpan>}
      </$LabelStyled>
      {postLabel}
    </$LabelWrap>
  );
};

Label.displayName = 'Label';
