import { darken } from 'polished';
import { DefaultTheme } from 'styled-components';

import { getFieldColorFromStatus } from '../common/helpers/getFieldColorFromStatus';
import { InputStatus } from '../common/helpers/getFinalFieldState.types';

export const getBorderColor = ({
  theme,
  status,
  disabled,
  readOnly,
  $isNewUiEnabled,
}: {
  theme: DefaultTheme;
  disabled?: boolean;
  readOnly?: boolean;
  status?: InputStatus;
  $isNewUiEnabled?: boolean;
}) => {
  const defaultColor = $isNewUiEnabled
    ? theme.colors.default
    : theme.colors.default;

  const fieldColorFromStatus = getFieldColorFromStatus({
    status,
    theme,
  });

  if (fieldColorFromStatus) {
    return fieldColorFromStatus;
  }

  if (disabled || readOnly) {
    return theme.colors.default;
  }

  if (!status) {
    return defaultColor;
  }

  return getFieldColorFromStatus({ status, theme }) ?? defaultColor;
};

export const getOnHoverBorderColor = ({
  theme,
  status,
  disabled,
  readOnly,
  $isFocusAndHoverBehavioursHandled,
  $isNewUiEnabled,
}: {
  theme: DefaultTheme;
  disabled?: boolean;
  readOnly?: boolean;
  $isFocusAndHoverBehavioursHandled?: boolean;
  status?: InputStatus;
  $isNewUiEnabled?: boolean;
}) => {
  const defaultColor = $isNewUiEnabled
    ? theme.newUI.variationColors.light4
    : theme.colors.primary;

  const fieldColorFromStatus = getFieldColorFromStatus({
    status,
    theme,
  });

  if (!$isFocusAndHoverBehavioursHandled) {
    return theme.colors.default;
  }

  if (fieldColorFromStatus) {
    return darken(0.1, fieldColorFromStatus);
  }

  if (disabled || readOnly) {
    return theme.colors.default;
  }

  return defaultColor;
};
