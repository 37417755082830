import React, { useCallback, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  CURRENCIES,
  INPUT_TYPES,
} from '@savgroup-front-common/constants';
import {
  Autocomplete,
  FormGroup,
  Input,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import {
  formatCurrencyCode,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { formatStringToIntlPrice } from '@savgroup-front-common/core/src/formatters/intl';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { CUSTOMER_TYPE, FLEX_ALIGN } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import useSellerSelfcareConfiguration from 'authenticator/hooks/useSellerSelfcareConfiguration';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import PostLabelTooltip from '../../../../atoms/PostLabelTooltip';

import useChoicePartner from './ChoicePartnerProduct.hooks';
import {
  $CloseButton,
  $HelperButton,
  $HelperContainerButtonClose,
  $HelperContainerTitle,
  $Img,
} from './ChoicePartnerProduct.styles';
import useGetModelOptions from './hooks/useGetModelOptions';
import messageForm from './messages';

interface ChoicePartnerStoreProps {
  context: CreateClaimContext;
  values: CreateClaimValues;
}

const ChoicePartnerProduct: React.FC<ChoicePartnerStoreProps> = ({
  values,
}) => {
  const [isHelperOpen, setIsOpenHelper] = useState<boolean>(false);
  const theme = useTheme();
  const { formContext, onSubmit, errors, isLoading, partnerSelected } =
    useChoicePartner({
      values,
    });
  const { sellerConfiguration } = useSellerSelfcareConfiguration({
    sellerId: partnerSelected?.id,
  });

  const { control, formState } = formContext;

  const isValid = Object.keys(formState.errors).length === 0;

  const {
    options: productOptions,
    isLoading: isModelLoading,
    setSearchTerms,
  } = useGetModelOptions({
    seller: partnerSelected,
  });

  const handleHelper = useCallback(() => {
    setIsOpenHelper((prev) => !prev);
  }, []);

  const customerType = values.activatedSellerPartnersIdSelected?.customerType;
  const isBusiness = customerType === CUSTOMER_TYPE.BUSINESS;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CHOICE_PARTNER_PRODUCT}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <FormGroup>
            <Controller
              control={control}
              name="choicePartnerProduct"
              render={({ field }) => {
                return (
                  <Autocomplete
                    ref={field.ref}
                    name={field.name}
                    label={messageForm.product}
                    errors={errors}
                    options={productOptions}
                    onInputChange={(input) => setSearchTerms(input)}
                    onChange={field.onChange}
                    isLoading={isModelLoading}
                    value={field.value}
                    placeholder={
                      messageForm.choicePartnerProductInformationPlaceholder
                    }
                    postLabel={
                      <$HelperButton
                        hollow
                        naked
                        underline
                        type={BUTTON_TYPES.BUTTON}
                        onClick={handleHelper}
                        small
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={
                            messageForm.choicePartnerProductInformationHelper
                          }
                        />
                      </$HelperButton>
                    }
                    fullWidthLabel
                    tabIndex={0}
                    isRequired
                    filterOption={null}
                  />
                );
              }}
            />
          </FormGroup>
          {!isBusiness && (
            <FormGroup>
              <Controller
                control={control}
                name="choicePartnerProductPrice"
                render={({ field }) => {
                  return (
                    <Input
                      type={INPUT_TYPES.TEXT}
                      name={field.name}
                      label={messageForm.productPrice}
                      errors={errors}
                      value={field.value}
                      suffixInsideInput={
                        <SafeFormattedMessageWithoutSpread
                          message={formatCurrencyCode(CURRENCIES.EUR)}
                        />
                      }
                      onBlur={(event) =>
                        field.onChange(
                          formatStringToIntlPrice({
                            amount: event.target.value,
                            currency: CURRENCIES.EUR,
                          }),
                        )
                      }
                      postLabel={
                        <PostLabelTooltip>
                          <SafeFormattedMessageWithoutSpread
                            message={messageForm.productPriceTooltip}
                          />
                        </PostLabelTooltip>
                      }
                      disableBrowserAutocomplete
                      onChange={field.onChange}
                      isRequired
                    />
                  );
                }}
              />
            </FormGroup>
          )}
        </StepLayout>
      </form>

      {sellerConfiguration && (
        <SwipeableDrawer
          isOpen={isHelperOpen}
          size={DRAWER_SIZE.SMALL}
          onClose={() => setIsOpenHelper(false)}
          hasBackdrop
          hasBackdropScroll={false}
          hasCloseButton={false}
        >
          <$HelperContainerButtonClose>
            <$CloseButton
              onClick={handleHelper}
              primary
              naked
              small
              type={BUTTON_TYPES.BUTTON}
              icon={<MyAccountIcon icon={ICONS_TYPE.CROSS_ICON} />}
            />
          </$HelperContainerButtonClose>
          <$HelperContainerTitle>
            <Heading
              level={2}
              align={FLEX_ALIGN.LEFT}
              color={theme.colors.black}
            >
              <SafeFormattedMessageWithoutSpread
                message={messageForm.productHelperTitle}
              />
            </Heading>
          </$HelperContainerTitle>
          <SafeFormattedMessageWithoutSpread
            message={sellerConfiguration?.productHelpText}
          />
          {sellerConfiguration?.productHelpImageUrls.map((url) => {
            return (
              <div key={url} style={{ marginTop: '2rem' }}>
                <$Img src={url} alt="helperImage" />
              </div>
            );
          })}
        </SwipeableDrawer>
      )}
    </FormProvider>
  );
};

ChoicePartnerProduct.displayName = 'ChoicePartnerProduct';

export default ChoicePartnerProduct;
