import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDebounce } from 'react-use';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { SellerService } from 'authenticator/api';

import messages from '../messages';

import { OnboardingFormFieldValues } from './useOnboardingFormContext';

const useGetDomainNameFromCName = ({
  formContext,
}: {
  formContext: UseFormReturn<OnboardingFormFieldValues>;
}) => {
  const [domainName, setDomainName] = useState<string | undefined>(undefined);
  const { watch, setError } = formContext;
  const {
    isLoading: isDomainNameLoading,
    data: domain,
    mutateAsync,
  } = useMutation(['useGetDomainNameFromCName'], async (cName?: string) => {
    if (!cName) {
      return undefined;
    }
    const response = await SellerService.getDomainNameFromCNameQuery({
      cName,
    });

    if (response.failure) {
      return undefined;
    }

    if (!response.value.available) {
      setError('cName', {
        message: safeFormattedIntlString(messages.cNameAlreadyUsed),
      });
    }

    setDomainName(cName);

    return response.value;
  });
  const watchedCName = watch('cName');

  useDebounce(
    () => {
      if (watchedCName !== undefined) {
        mutateAsync(watchedCName);
      }
    },
    600,
    [watchedCName],
  );

  return {
    isDomainNameLoading:
      isDomainNameLoading ||
      (domainName !== watchedCName && Boolean(watchedCName)),
    domain,
    domainName,
  };
};

export default useGetDomainNameFromCName;
