import React, { FC, ReactElement } from 'react';
import { Transition } from 'react-transition-group';

import BannerBaseWithAnimation, {
  BannerBaseProps,
} from './BannerBaseWithAnimation';

interface NotificationProps extends BannerBaseProps {
  isOpen?: boolean;
  icon?: ReactElement;
}

const Banner: FC<React.PropsWithChildren<NotificationProps>> = ({
  onClose = undefined,
  hollow = false,
  isOpen = true,
  hasIcon = true,
  animationDuration = 300,
  children,
  isFluid = false,
  notificationType,
  icon,
  isNewUi,
}) => {
  return (
    <Transition timeout={animationDuration} in={isOpen}>
      {(animationState) => (
        <BannerBaseWithAnimation
          animationState={animationState}
          animationDuration={animationDuration}
          notificationType={notificationType}
          onClose={onClose}
          hollow={hollow}
          hasIcon={hasIcon}
          isFluid={isFluid}
          icon={icon}
          isNewUi={isNewUi}
        >
          {children}
        </BannerBaseWithAnimation>
      )}
    </Transition>
  );
};

export default React.memo(Banner);
