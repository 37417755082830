import React from 'react';
import { useTheme } from 'styled-components';

import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { useGetSellerAcceptedLanguages } from '@savgroup-front-common/core/src/molecules/LanguageChooser/hooks/useGetSellerAcceptedLanguages';
import {
  LOGO_POSITION,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { $HeaderContainer, $MenuContainer, $RightSide } from './Header.styles';
import Logo from './logo/Logo';

interface HeaderTestProps {
  sellerId: string;
}

const Header: React.FC<HeaderTestProps> = ({ sellerId }) => {
  const { acceptedLanguagesBySeller } = useGetSellerAcceptedLanguages({
    sellerId,
  });
  const theme = useTheme();

  const position = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.LOGO,
    property: SUPPORTED_PROPERTIES.POSITION,
    fallback: LOGO_POSITION.LEFT,
  }) as LOGO_POSITION;

  const isLanguageChooseDisplayed = !!acceptedLanguagesBySeller?.length;

  return (
    <$HeaderContainer componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}>
      <$MenuContainer $position={position}>
        {position === LOGO_POSITION.CENTER && isLanguageChooseDisplayed && (
          <div />
        )}

        <Logo sellerId={sellerId} />
        <$RightSide>
          <LanguageChooser
            acceptedLanguagesBySeller={acceptedLanguagesBySeller}
          />
        </$RightSide>
      </$MenuContainer>
    </$HeaderContainer>
  );
};

export default Header;
